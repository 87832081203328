import React from 'react';
import { Pagination, useMediaQuery } from '@mui/material';
import { pagination } from '@/Interface/interface';
const PaginationBlock = (props: {
  handleChange:
    | ((event: React.ChangeEvent<unknown>, page: number) => void)
    | undefined;
  pagination: Boolean;
  paginationData: pagination | undefined;
}) => {
  return (
    <>
      {props.paginationData !== undefined ? (
        <Pagination
          count={Math.ceil(
            props.paginationData?.total / props.paginationData?.pageSize,
          )}
          page={props.paginationData?.page}
          onChange={props.handleChange}
          siblingCount={useMediaQuery('(max-width:500px)') ? 1 : 0}
          boundaryCount={useMediaQuery('(max-width:500px)') ? 0 : 2}
          shape="rounded"
          className="pagination-block text-center border border-color-1 p-1"
          sx={{
            display:
              props.paginationData?.total > 15 && props.pagination
                ? ''
                : 'none',
            marginTop: 3,
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default PaginationBlock;
