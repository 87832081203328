import Slider from 'react-slick';
import { Box } from '@mui/material';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import Link from 'next/link';
export interface bannerData {
  id?: number;
  isActive?: boolean;
  url?: string | undefined;
  index: number;
  image: {
    url: string;
    width: number;
    height: number;
  };
}
const HomeSlider = ({ homePageBanner }: any) => {
  const [bannerImage, setBannerImage] = useState<bannerData[]>([]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const sortedBannerImage = homePageBanner || [];
        sortedBannerImage.sort((a: any, b: any) => a.index - b.index);
        setBannerImage(sortedBannerImage);
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };
    fetchBanner();
  }, []);

  return (
    <Box className="home-slider section-bottom-gapping">
      <Slider {...settings}>
        {bannerImage &&
          bannerImage?.map((res, i) => {
            const sliderImage = res && res.image && res.image.url;
            const imageWidth = res?.image?.width || 1170;
            const imageHeight = res?.image?.height || 530;
            const imageUrl = process.env.API_BASE_URL + sliderImage;
            const navigationURL = res.url || '';
            return (
              <Link key={i} href={navigationURL}>
                <Image
                  loading="lazy"
                  key={i}
                  // priority
                  src={imageUrl}
                  alt="slider1"
                  width={imageWidth}
                  height={imageHeight}
                  style={{ border: 'none' }}
                />
              </Link>
            );
          })}
      </Slider>
    </Box>
  );
};

export default HomeSlider;
