import { Box, Grid, Typography } from '@mui/material';
import HomeSlider from '../CommonComponent/homeSlider';
import { useState, useContext, useEffect } from 'react';
import ProductBlock from '@/CommonComponent/productBlock';
import { ROUTE, SeoInfo } from '@/Interface/interface';
import {
  getBrandAPI,
  getDevicesAPI,
  getHomePageBanner,
} from '@/Configuration/API';
import Slider from 'react-slick';
import { Context } from './_app';
import MetaDescription from '@/CommonComponent/metaDescription';
import ErrorBoundary from './ErrorBoundary';
import { convertIntoString, getCurrentURL } from '@/Configuration/Service';
import Link from 'next/link';
import Image from 'next/image';
import defaultSmartPhone from '../Assets/Images/defaultSmartphone.jpg';
import { GetServerSidePropsContext } from 'next';
import { headerReqCountryCode } from '@/CommonComponent/currencyDropDown';
import { useRouter } from 'next/router';

export default function Home({
  popular,
  featured,
  brandDevice,
  locale,
  countryCode,
  homePageBanner,
}: any) {
  const router = useRouter();
  const [isLoaded, setIsLoaded] = useState(false);
  const [popularData, setPopularData] = useState(popular);
  const [featureData, setFeatureData] = useState(featured);
  const {
    setTagArr,
    tagArr,
    currencyDropDownValue,
    isCurrencyCodeDropDownChange,
  } = useContext(Context);

  const settings = {
    speed: 4000,
    dots: false,
    infinite: true,
    slidesToShow: 6,
    autoplay: true,
    autoplaySpeed: 1,
    cssEase: 'linear',
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: true,
    // swipe: true,
    // fade: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          centerMode: false,
          variableWidth: true,
          speed: 4000,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          centerMode: false,
          variableWidth: true,
          speed: 4000,
        },
      },
    ],
  };
  useEffect(() => {
    setIsLoaded(true);
  }, [isLoaded]);
  const getData = async () => {
    const result = await getDevicesAPI('/tag?tags[]=popular', countryCode);
    const featured = await getDevicesAPI('/tag?tags[]=featured', countryCode);
    if (result?.status === 200) {
      setPopularData(result.data);
    }
    if (featured.status === 200) {
      setFeatureData(featured.data);
    }
  };
  locale = currencyDropDownValue;
  useEffect(() => {
    if (isCurrencyCodeDropDownChange) {
      getData();
    }
  }, [currencyDropDownValue]);
  return (
    <ErrorBoundary>
      <MetaDescription
        title={`TechSpecs.Info | Mobile Phone Specification, Price, Reviews and more`}
        keywords="Techspecs, techspecs.info, mobile phones, mobile specs, phone specification, mobile price, smartphones, online mobiles, Phone comparison, device specs, Device Specification, Mobile phone price, Mobile phone images, Mobile phone Finder, Smartphone Features, find your mobile"
        description="Discover, compare, and decide with detailed mobile tech specs and easy comparisons on our site. The ultimate source for news, reviews on the latest phones."
        url=""
        ogUrl="https://www.techspecs.info/"
        ogType="website"
        isNotForHomePage={true}
        robots="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        imageURL=""
        canonical={getCurrentURL()}
        country={`${router.locale}`}
      />
      <Grid className="home-page">
        <Box className="home-brand" py={{ xs: 2, sm: 4.5 }}>
          <div>
            <Slider {...settings}>
              {brandDevice?.length &&
                brandDevice?.map(
                  (
                    data: {
                      brandName: string;
                      slug: string;
                      image: {
                        alternativeText: string | null;
                        url: string | null;
                      };
                      seo: SeoInfo;
                    },
                    i: number,
                  ) => {
                    return (
                      <Grid
                        className="text-center"
                        key={i}
                        item
                        xs={4}
                        sm={2}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Link
                          // locale={currencyDropDownValue?.toLowerCase()}
                          href={`/${ROUTE.MOBILE_PHONES}/${convertIntoString(
                            data.brandName,
                          )}-brand`}
                          aria-label={`visit ${data.brandName} brand`}
                          onClick={() => {
                            if (tagArr?.length < 1) {
                              setTagArr((prev) => [
                                ...prev,
                                {
                                  label: 'brand_name',
                                  value: convertIntoString(data.brandName),
                                  match: data.brandName,
                                },
                              ]);
                            }
                          }}
                        >
                          <Grid
                            sx={{
                              width: {
                                xs: '100px',
                                sm: '120px',
                                md: '140px',
                                lg: '160px',
                              },
                              height: {
                                xs: '80px',
                                sm: '100px',
                                md: '120px',
                              },
                            }}
                          >
                            {data?.image?.url !== undefined ? (
                              <Image
                                loading="lazy"
                                className="m-auto"
                                src={`${process.env.API_BASE_URL}${data?.image?.url}`}
                                alt={`${
                                  data?.image?.alternativeText !== null
                                    ? data?.image?.alternativeText
                                    : `${data.brandName} logo`
                                }`}
                                width={0}
                                height={0}
                                sizes="100vw"
                                style={{
                                  objectFit: 'contain',
                                  width: '100%',
                                  height: '100%',
                                  display: isLoaded ? '' : 'none',
                                }}
                              />
                            ) : (
                              <Image
                                loading="lazy"
                                className="m-auto"
                                src={defaultSmartPhone}
                                alt={'product images'}
                                width={0}
                                height={0}
                                sizes="100vw"
                                style={{
                                  objectFit: 'contain',
                                  width: '100%',
                                  height: '100%',
                                  display: isLoaded ? '' : 'none',
                                }}
                              />
                            )}

                            <Typography
                              className="text-black"
                              mt={0}
                              sx={{
                                fontSize: {
                                  xs: '14px',
                                  sm: '16px',
                                  md: '18px,',
                                },
                                display: isLoaded ? '' : 'none',
                              }}
                            >
                              {data.brandName}
                            </Typography>
                          </Grid>
                        </Link>
                      </Grid>
                    );
                  },
                )}
            </Slider>
          </div>
        </Box>
        <HomeSlider homePageBanner={homePageBanner} />
        <Box
          className="home-product-section section-bottom-gapping"
          mt={{ xs: 1, sm: 2.5 }}
        >
          <ProductBlock
            productDataArray={popularData?.data?.filter(
              (res: { slug: string }, i: number) => i <= 7,
            )}
            sectionTitle="Popular Mobiles"
            columnCount={true}
            viewMoreLink={true}
            pagination={false}
            category="popular"
            path={`${ROUTE.POPULAR_PHONES}`}
            paginationData={undefined}
          />
        </Box>
        <Box className="home-product-section">
          <ProductBlock
            productDataArray={featureData?.data?.filter(
              (res: { slug: string }, i: number) => i <= 7,
            )}
            sectionTitle="Upcoming Mobiles"
            columnCount={true}
            viewMoreLink={true}
            pagination={false}
            category="Featured"
            path={`${ROUTE.UPCOMING_PHONES}`}
            paginationData={undefined}
          />
        </Box>
      </Grid>
    </ErrorBoundary>
  );
}
export const getServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const countryCode = context.req.cookies['CountryCode'] || 'US';
  try {
    const popular = await getDevicesAPI('/tag?tags[]=popular', countryCode);
    const featured = await getDevicesAPI('/tag?tags[]=featured', countryCode);
    const homeBrandImage = await getBrandAPI(countryCode);
    const selectedBrandData: {
      brandName: string;
      slug: string;
      image: {
        alternativeText: string | null;
        caption: string | null;
        createdAt: string | null;
        ext: string | null;
        folderPath: string | null;
        hash: string | null;
        height: number | null;
        id: number | null;
        mime: string | null;
        name: string | null;
        previewUrl: string | null;
        provider: string | null;
        provider_metadata: string | null;
        size: number | null;
        updatedAt: string | null;
        url: string | null;
        width: number | null;
      };
      seo: {
        canonicalURL: null | string;
        id: number;
        keywords: string;
        metaDescription: string;
        metaImage: null | string;
        metaRobots: string | null;
        metaTitle: string | null;
        metaViewport: string | null;
        structuredData: string | null;
      };
    }[] = [];
    const brandNameSet = new Set();
    if (homeBrandImage?.data?.homePageBrandData?.length) {
      for (const product of homeBrandImage?.data?.homePageBrandData) {
        const brandName = product.brand_name;
        if (!brandNameSet.has(brandName)) {
          brandNameSet.add(brandName);
          selectedBrandData.push({
            brandName,
            slug: product?.slug,
            image: product?.image,
            seo: product?.seo,
          });
        }
      }
    }
    const homePageBanner = (await getHomePageBanner())?.data?.data;
    const obj = {
      popular: popular.status === 200 ? popular?.data : null,
      featured: featured.status === 200 ? featured?.data : null,
      brandDevice: homeBrandImage.status === 200 ? selectedBrandData : null,
      title:
        'TechSpecs.Info | Mobile Phone Specification, Price, Reviews and more',
      description:
        'Discover, compare, and decide with detailed mobile specs and easy comparisons on our site. The ultimate source for news, reviews on the latest phones.',
      locale: context?.locale,
      countryCode: countryCode,
      homePageBanner: homePageBanner,
    };
    return {
      props: obj,
    };
  } catch (error) {
    return error;
  }
};
